//静态全局图片路径处理


// 预览文件地址
export function previewFileAddress(url) {
	if(!url) {
		return '';
	}
	const isIncloudProtocol = url.split(':')[0] === 'http' || url.split(':')[0] === 'https';
	if (!isIncloudProtocol) {
		return process.env.VUE_APP_BASE_API + url;
	}
	return url;
}
