import Vue from 'vue'
import App from './App.vue'


// 引入vant组件
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';

import router from './router'

// 封装图片接口方法
import { previewFileAddress } from "@/tools/imgPath"
Vue.prototype.$previewFileAddress = previewFileAddress;

Vue.config.productionTip = false

Vue.use(Vant).use(Toast);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
