<template>
  <div id="app">
    <router-view></router-view>
  </div>

</template>

<script>

export default {
  name: 'App',
  data(){
    return {
    }
  },
  components: {

  },
  methods:{
    
  }
}
</script>

<style>
*{
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}
</style>
