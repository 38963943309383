import Vue from "vue"
import VueRouter from "vue-router"
import { Toast } from 'vant';

Vue.use(VueRouter);

let routes = [
    {
        path:"/p/:id",
        name:"p",
        component: ()=>import("@/views/A.vue")
    },
    {
        path:"/l/:id",
        name:"l",
        component: ()=>import("@/views/B.vue")
    },
    {
        path:"/:selfCode",
        name:"c",
        component: ()=>import('@/views/C.vue'),
        meta:{
            hiddenTab:true,
        }
    },
    {
        path:"/x/:uuid",
        name:"x",
        component: ()=>import("@/views/X.vue")
    }
]

// 防止点击多次路由失败
const originaPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location){
    return originaPush.call(this, location).catch(err => err)
};

let router = new VueRouter({
    routes,
    mode:"history"
});

// 路由守卫
router.beforeEach((to,form,next)=>{
    if(form.name === "l" && to.name === "c"){
        to.meta.hiddenTab = true;
    }else{
        if(to.name === "c"){
            to.meta.hiddenTab = false;
        }
    }
    if(to.name == null){
        Toast.fail('请输入溯源码查询信息');
    }
    next(true);
})


export default router
